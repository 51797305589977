import { srGet, srPostJson, throwFetchError } from '@sevenrooms/core/api'

export interface ValidationResponse {
  id: string
  message: string
  url: string
}

export interface BulkCopyJobStatusResponse {
  status: 'PENDING' | 'IN_PROGRESS' | 'FAILED' | 'COMPLETED'
}

export const fetchValidationLogsOnAccessRuleCopy = async (
  venueGroupId: string,
  sourceVenueId: string,
  targetVenueId: string,
  selectedAccessRuleIds: string[],
  startDate: string
) =>
  srPostJson<ValidationResponse[]>('/api-yoa/booking_access/bulk_copy/validate', {
    venue_group_id: venueGroupId,
    source_venue_id: sourceVenueId,
    target_venue_id: targetVenueId,
    selected_access_rule_ids: selectedAccessRuleIds,
    start_date: startDate,
  })
    .then(throwFetchError)
    .then(response => response.data)

export const postAccessRuleBulkCopy = (
  venueGroupId: string,
  sourceVenueId: string,
  selectedTargetIds: string[],
  selectedAccessRuleIds: string[],
  startDate: string,
  overrideCopyWithWarnings: string
) =>
  srPostJson<ValidationResponse[]>('/api-yoa/booking_access/bulk_copy', {
    venue_group_id: venueGroupId,
    source_venue_id: sourceVenueId,
    target_venue_ids: selectedTargetIds,
    selected_access_rule_ids: selectedAccessRuleIds,
    start_date: startDate,
    override_copy_with_warnings: overrideCopyWithWarnings,
  })
    .then(throwFetchError)
    .then(response => response.data)

export const fetchAccessRuleBulkCopyStatus = async (venueGroupId: string) =>
  srGet<BulkCopyJobStatusResponse>('/api-yoa/booking_access/bulk_copy_status', { venue_group_id: venueGroupId })
    .then(throwFetchError)
    .then(response => response.data)
