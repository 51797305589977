import { defineMessages } from '@sevenrooms/core/locales'

export const accessRulesBulkCopyMessages = defineMessages({
  title: {
    id: 'accessRuleBulkCopy.title',
    defaultMessage: 'Access Rule Bulk Copy Overview:',
  },
  accessRuleSelect: {
    id: 'accessRuleBulkCopy.accessRuleSelect',
    defaultMessage: 'Select Access Rule(s) to copy over',
  },
  accessRuleSelectDays: {
    id: 'accessRuleBulkCopy.accessRuleSelect.days',
    defaultMessage: 'Days',
  },
  accessRuleSelectDateRange: {
    id: 'accessRuleBulkCopy.accessRuleSelect.dateRange',
    defaultMessage: 'Date Range',
  },
  accessRuleSelectTime: {
    id: 'accessRuleBulkCopy.accessRuleSelect.time',
    defaultMessage: 'Time(s)',
  },
  accessRuleSelectShiftCategories: {
    id: 'accessRuleBulkCopy.accessRuleSelect.shiftCategories',
    defaultMessage: 'Shift categories',
  },
  accessRuleSelectPartySize: {
    id: 'accessRuleBulkCopy.accessRuleSelect.partySize',
    defaultMessage: 'Party Size',
  },
  accessRuleSelectAll: {
    id: 'accessRuleBulkCopy.accessRuleSelect.all',
    defaultMessage: 'Replace all Access Rules',
  },
  accessRuleSelectCustom: {
    id: 'accessRuleBulkCopy.accessRuleSelect.custom',
    defaultMessage: 'Select Access Rule(s) to copy existing rules to Target Venue(s)',
  },
  progressing: {
    id: 'accessRuleBulkCopy.progressing',
    defaultMessage: 'Bulk Copy Progressing',
  },
  venues: {
    id: 'accessRuleBulkCopy.venues',
    defaultMessage: 'Venues',
  },
  pods: {
    id: 'accessRuleBulkCopy.pods',
    defaultMessage: 'Pods',
  },
  warnings: {
    id: 'accessRuleBulkCopy.warning',
    defaultMessage: 'Warnings',
  },
  editSetting: {
    id: 'accessRuleBulkCopy.editSetting',
    defaultMessage: 'Edit Setting',
  },
  schedule: {
    id: 'accessRuleBulkCopy.schedule',
    defaultMessage: 'Define the Start Date for the new Access Rules.',
  },
  overrideWarningChoicesSkip: {
    id: 'accessRuleBulkCopy.overrideWarningChoices.skip',
    defaultMessage: 'Do not copy over access rules with warnings to target venues. All rules without warnings will still be copied.',
  },
  overrideWarningChoicesSkipDescription: {
    id: 'accessRuleBulkCopy.overrideWarningChoices.skip.description',
    defaultMessage: 'Example: A rule with a Seating Area not found in the target venue would not be copied at all.',
  },
  overrideWarningChoicesContinue: {
    id: 'accessRuleBulkCopy.overrideWarningChoices.continue',
    defaultMessage: 'Continue to copy over access rules with warnings to target venues without the appropriate mappings.',
  },
  overrideWarningChoicesContinueDescription: {
    id: 'accessRuleBulkCopy.overrideWarningChoices.continue.description',
    defaultMessage:
      'Example: A rule with a Seating Area not found in the target venue would be copied without that seating area as a restriction, potentially leaving the rule able to be booked at any table.',
  },
} as const)
