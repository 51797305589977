import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { HStack } from '@sevenrooms/core/ui-kit/layout'

export function AccessRuleBulkCopySlideoutFooter({ close }: { close?: () => void }) {
  const { formatMessage } = useLocales()

  return (
    <HStack spacing="s" ml="auto">
      <Button data-test="ui-kit-slideout-cancel" onClick={close} variant="tertiary">
        {formatMessage(commonMessages.cancel)}
      </Button>
    </HStack>
  )
}
